window.onload = setup_commentable;

function setup_commentable() {
	if (!$('#document_commentable').prop('checked'))
		$("#comments_due").hide()
}
window.commentable = () => {
	checkbox = $('#document_commentable').prop('checked')
	if (checkbox) 
		$("#comments_due").show(200)
	else 
		$("#comments_due").hide(200)
}